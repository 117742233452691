import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { getSelfMember } from '../../services/members';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}) => {
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component/ooi-component#controller
  const wixCodeApi = controllerConfig.wixCodeApi;
  const currentMember = await getSelfMember(flowAPI)();
  const navigateToAddListing = () => {
    const url = wixCodeApi.location.baseUrl + '/productform';
    wixCodeApi?.location?.to!(url);
  };
  return {
    async pageReady() {
      controllerConfig.setProps({
        instance: flowAPI.controllerConfig.appParams.instance,
        navigateToAddListing,
        currentMember: currentMember?.member,
        baseUrl: wixCodeApi.location.baseUrl,
      });
      // Here you can pass props to the Widget
    },
  };
};

export default createController;
