import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { MembersNgApi } from '@wix/ambassador-members-ng-api/http';

export const getSelfMember = (flowAPI: ControllerFlowAPI) => async () => {
  const members = MembersNgApi(
    '/_api/members',
    (flowAPI.httpClient as any).defaults,
  ).Members()({
    Authorization: flowAPI.controllerConfig.appParams.instance,
  });
  try {
    return await members.getMyMember({});
  } catch (error: any) {
    console.log('something bad happened :(', error);
  }
};
export const getMemberById =
  (flowAPI: ControllerFlowAPI) => async (id: string) => {
    const members = MembersNgApi(
      '/_api/members',
      (flowAPI.httpClient as any).defaults,
    ).Members()({
      Authorization: flowAPI.controllerConfig.appParams.instance,
    });
    try {
      return await members.getMember({ id });
    } catch (error: any) {
      console.log('something bad happened :(', error);
    }
  };
